import {
  useFeaturesService,
  useRetailCollectionsService,
  useUserService,
} from 'dx-sdk/build/services';
import usePostPortalLeadForm from 'dx-sdk/build/services/forms/postPortalLeadForm';
import useLocationBySearchService from 'dx-sdk/build/services/location/bySearch';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { fireSegmentEvent } from '../utils';
import { apolloClient } from '../utils/utils';
import useAuthorization from 'dx-sdk/build/authorization/useAuthorization';
import { jwtDecode } from 'jwt-decode';

export const DXAccountContext = createContext({
  state: {
    account: null,
    features: null,
    userServiceLoading: null,
    featuresServiceLoading: null,
    searchResults: null,
    isAuthenticated: null,
    globalLoading: null,
    plannerFormIsSaved: null,
    favorites: [],
    favoritesLoaded: false,
  },
  actions: {},
});

const DXAccountContextProvider = ({ children, dealer }) => {
  const [search, setSearch] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [error, setError] = useState(null);
  const [dxRedirectUri, setdxRedirectUri] = useState(null);
  const [completeContent, setCompleteContent] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [plannerFormSavedHCs, setPlannerFormSavedHCs] = useState([]);
  const [plannerFormSavedProfileIds, setPlannerFormSavedProfileIds] = useState(
    []
  );
  const [plannerSaved, setPlannerSaved] = useState(false);
  const [token, setToken] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userZipCode, setUserZipCode] = useState('');
  const [userContactMethod, setUserContactMethod] = useState([]);
  const [userAccountID, setUserAccountID] = useState(null);
  const [userIdentityProviderUserId, setUserIdentityProviderUserId] =
    useState(null);
  const [queuedFavorite, setQueuedFavorite] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [favoritesLoaded, setFavoritesLoaded] = useState(false);
  const didMount = useRef(false);
  const [plannerProgressOnLoad, setPlannerProgressOnLoad] = useState(null);

  const {
    isAuthenticated,
    logout,
    user,
    loginWithPopup,
    getAccessTokenWithPopup,
    getAccessTokenSilently,
  } = useAuthorization();

  // get auth0 token
  useEffect(() => {
    if (user && !token) {
      getAccessTokenSilently().then(setToken).catch(setError);
    }
  }, [user, token]);

  // update token on expiration
  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const date = new Date();
      const expirationDifference = decodedToken?.exp
        ? decodedToken?.exp - date.getTime() / 1000
        : -1;

      if (expirationDifference <= 0) {
        getAccessTokenSilently().then(setToken).catch(setError);
      } else {
        setTimeout(() => {
          getAccessTokenSilently().then(setToken).catch(setError);
        }, expirationDifference * 1000);
      }
      console.log({ token });
    }
  }, [token]);

  // handle errors
  useEffect(() => {
    if (error) {
      if (error === 'consent_required') {
        getAccessTokenWithPopup().catch(setError);
      } else if (error === 'login_required') {
        loginWithPopup().catch(setError);
      }
    }
  }, [error]);

  const featuresService = useFeaturesService({
    client: apolloClient,
    token: token,
    condition: {
      type: 'retail',
    },
  });

  const locationBySeachService = useLocationBySearchService({
    client: apolloClient,
    search,
  });

  const portalLeadFormService = usePostPortalLeadForm({
    client: apolloClient,
    token: token,
  });

  const userService = useUserService({
    token: token,
    ...(user && {
      user: {
        identityProviderUserId: user?.sub,
        identityProviderId: 1,
        ...(user?.given_name && {
          firstName: user.given_name,
        }),
        ...(user?.first_name && {
          firstName: user.first_name,
        }),
        ...(user?.family_name && {
          lastName: user.family_name,
        }),
        ...(user?.last_name && {
          lastName: user.last_name,
        }),
      },
    }),
    client: apolloClient,
    include: {
      retailCollections: true,
      profile: true,
      plannerProgress: true,
    },
    lotNumber: dealer?.dealerId?.toString(),
    plannerType: 'retail',
    content: {
      FirstName: userFirstName || '',
      LastName: userLastName || '',
      EmailAddress: user?.email || userEmail,
      PortalName: 'Retail',
      DealerNumber: dealer?.dealerNumber || '',
      MarketingMeta: {},
    },
  });

  const collectionsService = useRetailCollectionsService({
    userId: userService?.data?.userId,
    token: token,
    client: apolloClient,
    lotNumber: dealer?.dealerId?.toString(),
  });

  const plannerSectionLookup = {
    'Home Buying Journey': 'journey',
    Budget: 'budget',
    Land: 'land',
    'Home Aspirations': 'aspirations',
  };

  useEffect(() => {
    if (isAuthenticated && dxRedirectUri) {
      history.push(dxRedirectUri);
      setdxRedirectUri(null);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setSearchResults(null);
    analytics.ready(() => {
      analytics.register({
        name: 'Insert Dealer Into Event Context',
        type: 'before',
        version: '1.0',
        isLoaded: () => true,
        load: (ctx) => Promise.resolve(ctx),
        page: (ctx) => {
          const pageCurrentContext = ctx.event.context || {};
          ctx.event.context = {
            dealerInformation: {
              region: dealer?.region || '',
              zone: dealer?.zone || '',
              brand: dealer?.brandAcronym || '',
              homeCenter: dealer?.dealerId || '',
            },
            ...pageCurrentContext,
          };
          return ctx;
        },
        track: (ctx) => {
          const currentContext = ctx.event.context || {};
          ctx.event.context = {
            dealerInformation: {
              region: dealer?.region || '',
              zone: dealer?.zone || '',
              brand: dealer?.brandAcronym || '',
              homeCenter: dealer?.dealerId || '',
            },
            ...currentContext,
          };
          return ctx;
        },
      });
    });
  }, []);

  useEffect(() => {
    analytics.ready(() => {
      analytics?.page();
    });
  }, [location.pathname]);

  useEffect(() => {
    if (
      isAuthenticated &&
      collectionsService?.data?.[0]?.inventoryItems &&
      queuedFavorite
    ) {
      actions.addQueuedFavorite(queuedFavorite);
    }
  }, [isAuthenticated, collectionsService?.data]);

  const addFavoriteModel = (modelNumber, serialNumber) => {
    collectionsService?.add({
      retailCollectionId: collectionsService?.data[0]?.id,
      modelNumber,
      serialNumber,
    });

    fireSegmentEvent('Facility Model Favorited', {
      modelNumber: modelNumber || '',
      serialNumber: serialNumber || '',
      eventContext: 'portal_home_favorited',
    });

    setQueuedFavorite(null);
  };

  // set favorites
  useEffect(() => {
    if (
      collectionsService &&
      collectionsService?.data &&
      !!collectionsService?.data?.length &&
      !collectionsService?.loading
    ) {
      // everything coming from graph
      const newValues =
        collectionsService?.data?.[0]?.inventoryItems.reduce(
          (models, model) => {
            return [
              ...models,
              {
                modelNumber: model?.modelNumber,
                serialNumber: model?.serialNumber,
                state: 'added',
                collectionId: model?.id,
                modelCollectionId: model?.id,
              },
            ];
          },
          []
        ) || [];

      // values from graph not in favorites
      const addedValues =
        newValues.filter(
          (value) =>
            !favorites.find(
              (favorite) =>
                favorite.modelNumber === value.modelNumber &&
                favorite.serialNumber === value.serialNumber
            )
        ) || [];

      // values in favorites but not graph
      const removedValues =
        favorites.filter(
          (favorite) =>
            !newValues.find(
              (value) =>
                value.modelNumber === favorite.modelNumber &&
                value.serialNumber === favorite.serialNumber
            )
        ) || [];

      // join added and current
      const joinedValues = [...addedValues, ...favorites];

      const updatedFavorites =
        joinedValues.map((favorite) => {
          const newFavorite = newValues.find(
            (value) =>
              value.modelNumber === favorite.modelNumber &&
              value.serialNumber === favorite.serialNumber
          );

          if (favorite.state === 'toRemove') {
            if (newFavorite) {
              collectionsService.remove(
                newFavorite?.modelCollectionId || favorite.modelCollectionId
              );
            }
            return {
              ...favorite,
              state: 'removing',
            };
          }
          if (favorite.state === 'toAdd') {
            if (!newFavorite) {
              collectionsService.add({
                retailCollectionId: favorite.collectionId,
                modelNumber: favorite.modelNumber,
                serialNumber: favorite.serialNumber,
              });

              fireSegmentEvent('Facility Model Favorited', {
                modelNumber: favorite?.modelNumber || '',
                serialNumber: favorite?.serialNumber || '',
                eventContext: 'portal_home_favorited',
              });
            }
            return {
              ...favorite,
              state: 'adding',
            };
          }
          if (
            !!removedValues.find(
              (value) =>
                value.modelNumber === favorite.modelNumber &&
                value.serialNumber === favorite.serialNumber
            )
          ) {
            return {
              ...favorite,
              state: 'removed',
            };
          }
          return {
            ...(newFavorite || favorite),
            state: 'added',
          };
        }) || [];
      setFavorites(updatedFavorites || []);
      setFavoritesLoaded(true);
    }
  }, [collectionsService?.data]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('planner_fs'));
    if (items) {
      setPlannerFormSavedHCs(items.homeCenters);
      setPlannerFormSavedProfileIds(items.profileIds);
    }
  }, []);

  useEffect(() => {
    if (plannerFormSavedHCs && plannerFormSavedHCs.length) {
      let filteredHCList = [...new Set(plannerFormSavedHCs)];
      let filteredProfileIdList = [...new Set(plannerFormSavedProfileIds)];
      localStorage.setItem(
        'planner_fs',
        JSON.stringify({
          homeCenters: filteredHCList,
          profileIds: filteredProfileIdList,
        })
      );
    }
  }, [plannerFormSavedHCs, plannerFormSavedProfileIds]);

  useEffect(() => {
    let lotsStored =
      plannerFormSavedHCs && plannerFormSavedHCs.length
        ? plannerFormSavedHCs?.map((i) => i)
        : [];
    let profileIdsStored =
      plannerFormSavedProfileIds && plannerFormSavedProfileIds.length
        ? plannerFormSavedProfileIds?.map((i) => i)
        : [];
    if (
      !location?.state?.activeQuestionLink &&
      lotsStored.includes(dealer?.dealerId) &&
      profileIdsStored.includes(userService?.data?.profile?.profileId)
    ) {
      setPlannerSaved(true);
    }
  }, [
    plannerFormSavedHCs,
    plannerFormSavedProfileIds,
    userService?.data?.profile?.profileId,
  ]);

  // set starting plannerProgress on page load
  useEffect(() => {
    if (userService.data?.plannerProgress !== undefined) {
      const percentCompleted =
        userService.data?.plannerProgress[0].completed +
        userService.data?.plannerProgress[1].completed +
        userService.data?.plannerProgress[2].completed +
        userService.data?.plannerProgress[3].completed;
      setPlannerProgressOnLoad(percentCompleted);
    }
  }, [userService.data.plannerProgress]);

  // trigger planner started event
  useEffect(() => {
    if (
      plannerProgressOnLoad === 0 &&
      userService.data?.plannerProgress !== undefined
    ) {
      const percentCompleted =
        userService.data?.plannerProgress[0].completed +
        userService.data?.plannerProgress[1].completed +
        userService.data?.plannerProgress[2].completed +
        userService.data?.plannerProgress[3].completed;
      if (percentCompleted > 0) {
        fireSegmentEvent('MyHome Planner Started', {});
        console.log('MyHome Planner Started');
      }
    }
  }, [userService.data.plannerProgress]);

  useEffect(() => {
    const incomingContent =
      userService.data.plannerProgress?.reduce(
        (_complete, section) => [..._complete, ...section.completedContents],
        []
      ) || null;

    if (completeContent !== null && incomingContent) {
      const newContent = incomingContent.find(
        (content) => !completeContent.includes(content)
      );

      if (newContent) {
        const totalComplete =
          (userService.data.plannerProgress?.reduce(
            (total, section) => total + section.percentCompleted,
            0
          ) || 0) / (userService.data.plannerProgress?.length || 1);
        const newSection = userService.data.plannerProgress?.find((section) =>
          section.completedContents.includes(newContent)
        );

        fireSegmentEvent('MyHome Planner Progressed', {
          type: plannerSectionLookup[newSection?.name],
          eventContext:
            'portal_planner_' +
            plannerSectionLookup[newSection.name] +
            '_progressed',
        });

        if (newSection?.percentCompleted === 100) {
          fireSegmentEvent('MyHome Planner Progress Completed', {
            type: plannerSectionLookup[newSection.name],
            eventContext:
              'portal_planner_' +
              plannerSectionLookup[newSection.name] +
              '_progress_completed',
          });
        }

        if (totalComplete === 100) {
          fireSegmentEvent('MyHome Planner Progress Completed', {
            type: 'planner',
            eventContext: 'portal_planner_progress_complete',
          });
        }
      }
    }
    setCompleteContent(incomingContent);
  }, [userService?.data]);

  useEffect(() => {
    if (userService.data.profile) {
      setUserProfile(userService.data.profile);
      if (userProfile !== null) {
        const { likedFeatures, ...profile } = userService.data.profile;

        const updatedFieldKeys = Object.keys(profile).filter(
          (key) => userProfile[key] !== userService?.data?.profile?.[key]
        );

        const updatedProfile = updatedFieldKeys.reduce(
          (values, key) => ({
            ...values,
            [key]: userService.data.profile?.[key],
          }),
          {}
        );

        let segmentLikedFeatures =
          userProfile.likedFeatures && userProfile.likedFeatures.length > 0
            ? userProfile.likedFeatures.map((feature) => feature?.name)
            : [];

        if (!!updatedFieldKeys.length) {
          fireSegmentEvent('MyHome Planner Profile Updated', {
            budgetConfidence:
              userService?.data?.profile?.budgetConfidence || '',
            buyerSituation: userService?.data?.profile?.buyerSituation || null,
            dealerNumber: dealer?.dealerNumber || '',
            lot: dealer?.lotNumber || 0,
            email: user?.email || '',
            budget: userService?.data?.profile?.budget || 0,
            firstName: userFirstName || '',
            lastName: userLastName || '',
            phone: userService?.data?.phone || '',
            favoriteModels:
              favorites && favorites.length
                ? favorites
                    ?.filter((favorite) =>
                      ['toAdd', 'adding', 'added'].includes(favorite.state)
                    )
                    ?.map((fav) => ({
                      modelNumber: fav?.modelNumber,
                      serialNumber: fav?.serialNumber,
                    }))
                : [],
            purchaseStrategy: userService?.data?.profile?.fundingSource || '',
            preApproved:
              userService?.data?.profile?.fundingSource == 'pre_approved'
                ? true
                : false,
            hasLand: userService?.data?.profile?.hasLand || false,
            landType: userService?.data?.profile?.landType || '',
            landZip: userService?.data?.profile?.landZip || 0,
            likedFeatures: segmentLikedFeatures,
            preferredBaths: userService?.data?.profile?.preferredBaths || 0,
            preferredBeds: userService?.data?.profile?.preferredBeds || 0,
            planToBuyMonth:
              userService?.data?.profile?.targetMoveDateMonth || null,
            planToBuyYear:
              userService?.data?.profile?.targetMoveDateYear || null,
            preferredContactMethod:
              userService?.data?.preferredContactMethod?.[0] || '',
            writtenTextMessageConsent: false,
            zipCode: userZipCode || '',
            eventContext: 'portal_profile_updated',
          });
        }
      }
    }
  }, [userService.data.profile]);

  useEffect(() => {
    setUserFirstName(userService?.data?.firstName || '');
    setUserLastName(userService?.data?.lastName || '');
    setUserPhone(userService?.data?.phone || '');
    setUserEmail(user?.email || '');
    setUserZipCode(userService?.data?.zipcode || '');
    setUserAccountID(userService?.data?.userId);
    setUserContactMethod(userService?.data?.preferredContactMethod?.[0]);
    setUserIdentityProviderUserId(userService?.data?.identityProviderUserId);
  }, [
    userService?.data?.firstName,
    userService?.data?.lastName,
    userService?.data?.phone,
    userService?.data?.preferredContactMethod,
    userService?.data?.userId,
    userService?.data?.zipcode,
    userService?.data?.identityProviderUserId,
    user?.email,
  ]);

  useEffect(() => {
    if (didMount.current) {
      if (userAccountID) {
        analytics?.identify(userIdentityProviderUserId, {
          firstName: userFirstName || '',
          lastName: userLastName || '',
          phone: userPhone || '',
          preferredContactMethod: userContactMethod || '',
          email: userEmail || '',
        });
        LogRocket?.identify(userIdentityProviderUserId, {
          name: `${userFirstName} ${userLastName}`,
        });
      }
    } else {
      didMount.current = true;
    }
  }, [
    userFirstName,
    userLastName,
    userPhone,
    userContactMethod,
    userAccountID,
    userIdentityProviderUserId,
  ]);

  const actions = {
    favoriteLogin: (favObj, path) => {
      if (favObj) {
        setQueuedFavorite(favObj);
      }
      if (path) {
        setdxRedirectUri(path);
      } else {
        setdxRedirectUri(null);
      }
      loginWithPopup();
    },
    favoriteSignup: (favObj, path) => {
      if (favObj) {
        setQueuedFavorite(favObj);
      }
      if (path) {
        setdxRedirectUri(path);
      } else {
        setdxRedirectUri(null);
      }
      loginWithPopup({
        authorizationParams: {
          screen_hint: 'signup',
        },
      });
    },
    addQueuedFavorite: (model) => {
      let item = collectionsService?.data[0]?.inventoryItems.find(
        (collectionModel) =>
          collectionModel?.modelNumber === model?.modelNumber &&
          collectionModel?.serialNumber === model?.serialNumber
      );
      item ? null : addFavoriteModel(model?.modelNumber, model?.serialNumber);
    },
    toggleFavorite: (modelNumber, serialNumber) => {
      const favoriteCollection = collectionsService?.data?.find(
        (collection) => collection.label === 'Favorites'
      );
      const modelInFavorites = favorites.find(
        (favoriteModel) =>
          favoriteModel.modelNumber === modelNumber &&
          favoriteModel.serialNumber === serialNumber
      );

      if (favoriteCollection) {
        if (modelInFavorites) {
          if (modelInFavorites.state === 'adding') {
            setFavorites(
              favorites.map((favorite) =>
                favorite.modelNumber === modelNumber &&
                favorite.serialNumber === serialNumber
                  ? { ...favorite, state: 'toRemove' }
                  : favorite
              ) || []
            );
          }

          if (modelInFavorites.state === 'toAdd') {
            setFavorites(
              favorites.map((favorite) =>
                favorite.modelNumber === modelNumber &&
                favorite.serialNumber === serialNumber
                  ? { ...favorite, state: 'removed' }
                  : favorite
              ) || []
            );
          }

          if (modelInFavorites.state === 'added') {
            setFavorites(
              favorites.map((favorite) =>
                favorite.modelNumber === modelNumber &&
                favorite.serialNumber === serialNumber
                  ? { ...favorite, state: 'removing' }
                  : favorite
              ) || []
            );

            collectionsService.remove(modelInFavorites.modelCollectionId);
          }

          if (modelInFavorites.state === 'removing') {
            setFavorites(
              favorites.map((favorite) =>
                favorite.modelNumber === modelNumber &&
                favorite.serialNumber === serialNumber
                  ? { ...favorite, state: 'toAdd' }
                  : favorite
              ) || []
            );
          }

          if (modelInFavorites.state === 'toRemove') {
            setFavorites(
              favorites.map((favorite) =>
                favorite.modelNumber === modelNumber &&
                favorite.serialNumber === serialNumber
                  ? { ...favorite, state: 'toAdd' }
                  : favorite
              ) || []
            );
          }

          if (modelInFavorites.state === 'removed') {
            setFavorites(
              favorites.map((favorite) =>
                favorite.modelNumber === modelNumber &&
                favorite.serialNumber === serialNumber
                  ? { ...favorite, state: 'adding' }
                  : favorite
              ) || []
            );
            if (favoriteCollection?.id) {
              collectionsService.add({
                retailCollectionId: favoriteCollection.id,
                modelNumber,
                serialNumber,
              });

              fireSegmentEvent('Facility Model Favorited', {
                modelNumber: modelNumber || '',
                serialNumber: serialNumber || '',
                eventContext: 'portal_home_favorited',
              });
            }
          }
        } else {
          if (favoriteCollection?.id) {
            setFavorites([
              ...favorites,
              {
                modelNumber,
                serialNumber,
                modelId: 0,
                collectionId: favoriteCollection.id,
                modelCollectionId: 0,
                state: 'adding',
              },
            ]);

            collectionsService.add({
              retailCollectionId: favoriteCollection.id,
              modelNumber,
              serialNumber,
            });

            fireSegmentEvent('Facility Model Favorited', {
              modelNumber: modelNumber || '',
              serialNumber: serialNumber || '',
              eventContext: 'portal_home_favorited',
            });
          }
        }
      }
    },
    showLogin: (path) => {
      if (path) {
        setdxRedirectUri(path);
      } else {
        setdxRedirectUri(null);
      }
      loginWithPopup();
    },
    showSignup: (path) => {
      if (path) {
        setdxRedirectUri(path);
      } else {
        setdxRedirectUri(null);
      }
      loginWithPopup({ screen_hint: 'signup' });
    },
    updateAccountRetail: (updatedUser) => {
      delete updatedUser.email;
      const sendUser = {
        userId: userService?.data?.userId,
        ...updatedUser,
      };
      userService.patch({
        user: user ? sendUser : null,
      });
    },
    updateProfileRetail: (updatedProfile) => {
      delete updatedProfile.likedFeatures;
      delete updatedProfile.email;

      userService.patchProfile({ profile: updatedProfile });
    },
    addFeature: (featureId) => {
      userService.addFeature({
        profileId: userService.data?.profile.profileId,
        featureId,
      });
    },
    removeFeature: (featureId) => {
      userService.removeFeature({
        profileId: userService.data?.profile.profileId,
        featureId,
      });
    },
    markContentComplete: (plannerObject, contentTag) => {
      if (!plannerObject?.completedContents.includes(contentTag)) {
        userService.markContentComplete({
          plannerType: 'retail',
          lotNumber: dealer?.dealerId?.toString(),
          contentTag,
        });
      }
    },
    markContentIncomplete: (plannerObject, contentTag) => {
      userService.markContentIncomplete({
        plannerType: 'retail',
        lotNumber: dealer?.dealerId?.toString(),
        contentTag,
      });
    },
    locationBySeachService: (search) => {
      setSearch(search);
    },
    accountLogout: () => {
      logout({
        logoutParams: {
          returnTo:
            typeof window !== 'undefined' ? window.location.origin : '/',
        },
      });
      analytics?.track('CTA Clicked', {
        isNav: false,
        type: 'text',
        text: 'Log Out',
        eventContext: 'portal_logout',
      });
    },
    leadFormSubmit: (
      content,
      setFormSubmitted,
      setFormError,
      setSubmitDisabled,
      setSubmissionLoading
    ) => {
      portalLeadFormService
        ?.post({ content })
        .catch((e) => {
          console.log(e);
          setSubmitDisabled(false);
          setSubmissionLoading(false);
          setFormError('Unexpected error occurred. Please try again later.');
        })
        .then((...params) => {
          if (params[0] === undefined) {
            setFormSubmitted(false);
            setSubmitDisabled(false);
            setFormError('Unexpected error occurred. Please try again later.');
            setSubmissionLoading(false);
          }
          if (params[0]?.data.postPortalLeadForm.messageBody.length) {
            setFormSubmitted(true);
            setFormError('');
            setSubmitDisabled(false);
            setSubmissionLoading(false);
            setPlannerFormSavedHCs((hc) => [...hc, dealer?.dealerId]);
            setPlannerFormSavedProfileIds((p) => [
              ...p,
              userService?.data?.profile?.profileId,
            ]);
            fireSegmentEvent('MyHome Planner Submitted', {
              content: content,
              eventContext: 'portal_planner_form_submitted',
            });
          }
        });
    },
  };

  const currentContext = {
    state: {
      account: { email: user?.email, ...userService.data },
      features: featuresService?.data,
      userServiceLoading: userService?.loading,
      featuresServiceLoading: featuresService?.loading,
      searchResults: locationBySeachService?.data,
      isAuthenticated: isAuthenticated,
      globalLoading:
        (!userService ||
          userService?.loading ||
          !collectionsService ||
          !featuresService) &&
        !userService.data?.userId,
      plannerFormIsSaved: plannerSaved,
      favorites: favorites.filter((favorite) =>
        ['toAdd', 'adding', 'added'].includes(favorite.state)
      ),
      favoritesLoaded: favoritesLoaded,
    },
    actions,
  };

  return (
    <DXAccountContext.Provider value={currentContext}>
      {children}
    </DXAccountContext.Provider>
  );
};

DXAccountContextProvider.propTypes = {
  children: PropTypes.any,
  internalLink: PropTypes.bool,
};

export default React.memo(DXAccountContextProvider);

const brandRegionClientIDs = [
  {
    brand_region: 'OAK',
    client_ids: {
      dev: 'hcIGOdQMSMmoEsPlvnhkweB7dxyjy345',
      itg: '72vbKisn7d2c4NXnLUIIun1C1LI4YLPg',
      qua: '9srv0Cm1jF6bEbktC0wGZY9XOqy0F1Vv',
      prod: 'OnaSkamY2OGEPQ8J8xhbijQ7zBoOxTs4',
    },
  },
  {
    brand_region: 'LUV',
    client_ids: {
      dev: 'zt7hiSnKGRGPi1SHficIJQUfhG5vMQdX',
      itg: '7fBXiV5S1yUb8Db33OtAmDfOX0dcHfdQ',
      qua: 'KgdVmwlPMRnrmyWS8pzIKFAXLH88xGlF',
      prod: 'BrAkmqpNG3UklgFDvVs8QlmwE3b0zxWi',
    },
  },
  {
    brand_region: 'HBC',
    client_ids: {
      dev: 'kUAMQmzPHW8pFInQBZ3JEvvD4jDJsCCm',
      itg: '88ud11ijCIO8J8Bz0wRNrH2cuOfZ7bZg',
      qua: 'YbfQI7crSb1yXtjvltcIO5Ol6zg6pvgS',
      prod: '9abGm41tIdN7KAbxxBn2GUsTnPV8Vmr7',
    },
  },
  {
    brand_region: 'INT',
    client_ids: {
      dev: '9aJncScZb84QuwZM8DjOJK4cnDLglsEU',
      itg: 'yUjTCWFFLJ9uChAmkjESP4EXRDDqBGQX',
      qua: 'IBMOGWfw2IzaNav14GuJS9hUPq39Dwbz',
      prod: 'lkwSSZjrZpwRoVWcEm9SivtefWCuJqn8',
    },
  },
  {
    brand_region: 'SCO',
    client_ids: {
      dev: 'rjDUQl2VqJWevvhz9AEkevQAb2hYJ60O',
      itg: 'DfXF34zKXmyXjkM21skO5DaovM5QK9GE',
      qua: 'ClGYrDuEsotWSPRaqQdixz9Cq3gBTkx0',
      prod: 'U4ng0YkG9xY7B1a10GA5fv3RqWIVjvlZ',
    },
  },
  {
    brand_region: 'FRE',
    client_ids: {
      dev: 'hyYG1CSoXY3vZvx2eeMy41FYeC2TWaAC',
      itg: 'C0XWXIQcLvdN7Ye3V5KHOiTKSaJfBTZd',
      qua: '1dSpL5RHsfFvq57dmGNGlSmdhBGl5ZSE',
      prod: '9DG1kFICGE2YP6EGTw4RLUqq3lemIR40',
    },
  },
  {
    brand_region: 'CSL',
    client_ids: {
      dev: 'kQdIBXyC9lzGd3yDybPBizEUe3Ozw3IZ',
      itg: 'hBT7D9eRhGdDP7zYamzZ9E01lwzbuWnc',
      qua: '664SVIp6LUEXFWN1ylG255spOFbb3thA',
      prod: 'R93VOdp2npq3gpICh9hBObqAGy77lkTY',
    },
  },
  {
    brand_region: 'CLAWVA',
    client_ids: {
      dev: '0yKmLCH6KI6FSDPNBdHvfzZzrcHizqzb',
      itg: 'Mwm30v80lawYjiREJRpu26CsW6A9kOiY',
      qua: 'AYich7fVtNXtsIwdy4dnjmeYNgsBthjT',
      prod: 'VgqfNxkCuudPmLjGgJuJj49b7N9mK4Gj',
    },
  },
  {
    brand_region: 'CLAWCR',
    client_ids: {
      dev: 'p6CapiAw9YeVrWhVAWqs4LtZaO9INGsj',
      itg: 'R4sZ4ylcxdAGvNAtGm4eoRx6aD65tlGe',
      qua: 'ioiqNwGDslcHbStUxWG3Ru1LNhSH1fRO',
      prod: 'EYpw6lnA8s5NJVe4Y8YaLFT6rnozvMAB',
    },
  },
  {
    brand_region: 'CLAVIR',
    client_ids: {
      dev: 'HnX6OoVFhUxfz9yJhvvLLVQ8maM0u02R',
      itg: '6rYzLL2YGYpZhNXmcdd9iGx1gTOGlgLq',
      qua: 'fCN43dsvqnXqgBtztgk3vjyv42m8K22c',
      prod: '1El47okM8qtza7IYNYpnoVI3lpROhq36',
    },
  },
  {
    brand_region: 'CLATXS',
    client_ids: {
      dev: 'DdLkZ3ww08Tc8knOUN0nVhXozsi1cozf',
      itg: 'llRPOJEtNvQGOqieVT8ynBTj2l0J5b0A',
      qua: 'ckcgjNqJlgewfK4sKKfLAx4rcSdmSejX',
      prod: 'Th0YRRULYOikx2IzuibroxTOLjIq7jt5',
    },
  },
  {
    brand_region: 'CLATXN',
    client_ids: {
      dev: '60fiW1Qpaf9ualNQ3DmKpd1uItJfz1qe',
      itg: '15ITAvd173wkkzMYuDCMY0lsPaEKHnMU',
      qua: 'E74IvUQzfxGxGe9KcqhqqBPGHpFGP17e',
      prod: '1I716EwxTexS5STGOh9CGcW4D3WhOEdo',
    },
  },
  {
    brand_region: 'CLATNT',
    client_ids: {
      dev: 'lxJZ68gJ5VzHWEzSnGX126zDqtCUV5md',
      itg: 'DdwvS5N9FUsKfglqgiviybgejjiMWsJy',
      qua: 'PNa8XbbgZbvyuPdSXuvZljJISyduB8wP',
      prod: 'y9g377uomcVRPva3IMOIQhKcRy8iB3yF',
    },
  },
  {
    brand_region: 'CLATEN',
    client_ids: {
      dev: 'wPqoUWgrc1i1zE1RI7sjRVYzsLwDw2Od',
      itg: 'HEWQBw2cJijCXXhhcZLZE23r68plqk5S',
      qua: 'YjCcJ7r7GVeAzwPGNLqyIf0MAO4ThfSr',
      prod: 'qtifAHMrwOKRFo6FI4IBLBWcF1bgMlOz',
    },
  },
  {
    brand_region: 'CLABAM',
    client_ids: {
      dev: 'N42Xivi94uSGXb1tbz8fJWGbm9nTWMIj',
      itg: 'DfsIohfGoypZGOmroZ13wWVDwG8Qu83R',
      qua: '1GwLuXTlgFQXbrnBjE1KuJl0a2KQqvRy',
      prod: 'mEffibHGHjM6XQwjP3qDXYRhpIG3A7HH',
    },
  },
  {
    brand_region: 'CLAECR',
    client_ids: {
      dev: 'yA92VdfCQ5dt7x5v0CoIJcAFAIOnFzzw',
      itg: 'j8hs0qnNa9kV6Ad7VoXg8Hl4SBqgXmXy',
      qua: 'qabjr0by3WT3urqwqGbQnNw5fSzMdjGM',
      prod: 'cMSJ4wZdN2yAoaf8w8Uuj2XKBCwfe86t',
    },
  },
  {
    brand_region: 'CLAFLG',
    client_ids: {
      dev: '8r7p1DNpG7GV9oYJ8g1pBAjDZLU1m5g0',
      itg: 'CLz7QGZqnj2S417C3Wa4SfxemK8CMMw5',
      qua: 'uDfe5dAwnICaSz5rWPeRv6uypaa7TYNz',
      prod: 'mDd9KGevh3GHMAt57NTlzmVXkCfRiyml',
    },
  },
  {
    brand_region: 'CLAGUL',
    client_ids: {
      dev: 'wgaRwSSBap9JP3qnUmjaBfOvVBB1ig4D',
      itg: 'IYxAiRFBse0HgORupyAcg8hpik1eccun',
      qua: 'C7XdfrorKnHvGYAM4NWbf9Xjm67BJ7rY',
      prod: 'wLRI6EqSvl16CDt44OB6NIYQLldUgO6J',
    },
  },
  {
    brand_region: 'CLAKEN',
    client_ids: {
      dev: 'P7PCikOCcL4T4MiC7jZVJfOOclQ8s5ne',
      itg: 'IBodbSI57XlUsFiop0TgfIebSLXibptE',
      qua: 'bPZ44VyMFm1K0Je83AtTSZ46tMh9z2RR',
      prod: 'EiLJ1NQAJqY6ih4fNdAtkj3iDpFGkU0y',
    },
  },
  {
    brand_region: 'CLANER',
    client_ids: {
      dev: 'KKdKVbsvUIKK1ieT1RnsPXF0t6cy2XFA',
      itg: 'OrPMSEJbFtM8ep5eWK0eqiwat90TBOQy',
      qua: 'ZuooRg3CtAT99Ef7AUwgewfDDZEZwHAX',
      prod: 'IBRaLDU9TmdHPpQ20f3Qxml0SXls77ri',
    },
  },
  {
    brand_region: 'CLAPAC',
    client_ids: {
      dev: '4ZOOOJyeGQVqGAjLfbsnLKEBIKXlkzuP',
      itg: 'yLolSNhLW81QWmWkcp7mPLNOkarVBxu7',
      qua: 'partNDPeaimi436MazQRBAetKCuSfCHZ',
      prod: 'i2g9FA7yz9vrioS4Tnfd7Zkg68nCeEz5',
    },
  },
  {
    brand_region: 'CLAROC',
    client_ids: {
      dev: '9y1DSL4hiKPLPUUWbCB8FjompoGWBz7e',
      itg: 'uzoPVsx7GXgksG8iIbPsmnGd53tALIZD',
      qua: 'uEskSMoPVocvEE2GS8oTasWVl7wtfCnl',
      prod: 'lAn3vMiBmlMmqgENhcToup5lA2QSlXd0',
    },
  },
  {
    brand_region: 'CLASCA',
    client_ids: {
      dev: 'YWMCqjsJU9SBtlyUmHIWhwKyZ8oRZc2O',
      itg: 'sQGlpmyrtZrXFRKqWCH0OFKJ7OsCz3ZV',
      qua: 'mCvnzuphF2BaU00gkLnVb6i1KUE1dKcY',
      prod: '2q1ZEhRBqYoTYQmlTw6yTMNKuPVLdiq9',
    },
  },
  {
    brand_region: 'CLASER',
    client_ids: {
      dev: 'yVP6GglUY7mngDYjNZcyYggY1IHWj1tC',
      itg: 'FWBc5qjylbe4z5WpIBVfTCrq4T2GgNd5',
      qua: 'wQzDvMkeLUIe4aLBwAcwYhMz8pQa6Xe1',
      prod: 'CIw61AUc6Cl7BYbOibzIhvOgcyuhaZiQ',
    },
  },
];

export default brandRegionClientIDs;

import { ENVIRONMENT } from './utils/config';

export const isMobile = (size = 1051) => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < size;
  }
};

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  currency: 'USD',
});

export const removeTags = (text) => {
  const regex = /(<\/?br>)|(<[^>]*>)/g;
  return text.replace(regex, (_, br) => br || '');
};

export const formatCurrency = (value) => {
  if (value === null || value === undefined) return '';
  value = '' + value;
  value = value.replace('$', '').replace(',', '');
  const res = '$' + priceFormatter.format(value);
  return res === '$NaN' ? '' : res;
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const formatDealerBrand = (dealer) => {
  return dealer?.dealerName
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .split(' ')[0];
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const getMinScheduleVisitDate = () => {
  // min available date is the day after today (disregard current time)
  const currentDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  return currentDate.getDay() == 5
    ? currentDate.addDays(3)
    : currentDate.addDays(2);
};

export const isAvailableScheduleVisitDay = (date) => {
  const day = date.getDay();
  return day !== 0;
};

export const isSelectableScheduleVisitDate = (date) => {
  return date >= getMinScheduleVisitDate() && isAvailableScheduleVisitDay(date);
};

export const getUniqueHomeId = (home) => {
  return (
    home.serialNumber ||
    home.modelNumber ||
    home.inventoryId ||
    home.stockNumber
  );
};

const localStorage = {};

export const getLocalStorage = (key) => {
  let value;
  if (typeof window === 'undefined') {
    value = localStorage[key];
  } else {
    value = window.localStorage[key];
  }

  try {
    value = JSON.parse(value);
  } catch {}

  return value;
};

export const setLocalStorage = (key, value) => {
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }

  if (typeof window === 'undefined') {
    localStorage[key] = value;
    return;
  }

  return window.localStorage.setItem(key, value);
};

const sessionStorage = {};

export const getSessionStorage = (key) => {
  let value;
  if (typeof window === 'undefined') {
    value = sessionStorage[key];
  } else {
    value = window.sessionStorage[key];
  }

  try {
    value = JSON.parse(value);
  } catch {}

  return value;
};

export const setSessionStorage = (key, value) => {
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }

  if (typeof window === 'undefined') {
    sessionStorage[key] = value;
    return;
  }

  return window.sessionStorage.setItem(key, value);
};

export const parseInitialPropsContext = async (
  context,
  dealerService,
  homesService
) => {
  // called from getInitialProps in several pages...
  // given the page/component context parse and return dealer, hostname,
  // and optionally homes (only if homesService is passed in)
  // loading if necessary

  //if this page is being loaded from within SPA (link/router navigation)
  // we *should* have location.state object containing dealer and homes
  // but if not, we'll load them based off of lotnumber or the url for this request

  let lotNumber;
  let url;
  let dealer;
  let homes;
  let homesOnSale;
  let homesInStock;
  let moveInReadyHomes;
  let budgetHomes;
  let initialHomesOffset;
  let initialFilters;

  if (!context) {
    return {};
  }

  dealer = context.location?.state?.dealer;
  homes = context.location?.state?.homes;

  initialHomesOffset = context.location?.state?.hasOwnProperty('homesOffset')
    ? context.location?.state?.homesOffset
    : 10;
  initialFilters = context.location?.state?.hasOwnProperty('filters')
    ? context.location?.state?.filters
    : null;

  if (!dealer) {
    // if initial spa load, get lot from request object
    lotNumber =
      ENVIRONMENT.indexOf('prod') === -1 ? context.req?.query?.lotNumber : null;
    // if we don't have lot number we can use the url to determine the dealer
    url =
      context.req?.headers['vanity-url'] ||
      context.req?.hostname ||
      window?.location?.href;

    // remove schema and path from url
    url = url.replace('https://', '').replace('http://', '').split('/')[0];

    try {
      if (!lotNumber) {
        // try qstring
        const queryString = require('query-string');
        const params = queryString.parse(context.location?.search);
        lotNumber = params?.lotNumber;
      }
    } catch (e) {}
    dealer = await dealerService.getDealer(lotNumber, url);
  }

  if (homesService && !homes && dealer) {
    homes = await homesService.getHomesByLotNumber(dealer?.lotNumber);
  }

  homesOnSale = homes
    ?.filter(
      (home) =>
        (home.isSaleHome == true || home.isDefaultSaleHome == true) &&
        dealer?.websiteTemplateDescription !== 'BuilderModel'
    )
    .sort(function (a, b) {
      return b.isSaleHome - a.isSaleHome;
    });

  homesInStock = homes
    ?.filter((home) => home.stickerTypeId !== -1 && !home.isAvailableFloorPlan)
    .sort(function (a, b) {
      return (a.stickerTypeId === -7) - (b.stickerTypeId === -6);
    });

  moveInReadyHomes = homes
    ?.filter((home) => home.isOnLand == true && home.stickerTypeId !== -1)
    .sort(function (a, b) {
      return (
        (a.isOnLand && a.stickerTypeId === -7) -
        (b.isOnLand && b.stickerTypeId === -6)
      );
    });

  budgetHomes = homes
    ?.filter(
      (home) =>
        home.priceSort < 150000 &&
        home.priceLabel !== 'Contact Us For Pricing' &&
        home.stickerTypeId !== -1
    )
    .sort(function (a, b) {
      return (a.stickerTypeId === -7) - b.isAvailableFloorPlan;
    });

  const hostname = context.req ? url : window?.location.hostname;

  return {
    dealer,
    homes,
    homesOnSale,
    homesInStock,
    moveInReadyHomes,
    budgetHomes,
    hostname,
    initialHomesOffset,
    initialFilters,
  };
};

export const fireSegmentEvent = (title, content) => {
  if (!analytics || !title || !content) {
    return null;
  }

  return analytics?.track(title, content);
};

export const getGTMCode = () => {
  let envGTMCode =
    ENVIRONMENT.indexOf('prod') === -1 ? 'GTM-N667R7Q9' : 'GTM-MG3C7D8';

  return envGTMCode;
};

import React from 'react';
import ReactDOM from 'react-dom';
import routes from './routes';
import DXAuthorizationWrapper from './DXAuthorizationWrapper';
import { BrowserRouter } from 'react-router-dom';
import { After, ensureReady } from '@jaredpalmer/after';
import { apolloClient } from './utils/utils';
import { ApolloProvider } from '@apollo/client';

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

const appRender = (routes, data) => {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <DXAuthorizationWrapper dealer={data.initialData.dealer}>
          <After data={data} routes={routes} />
        </DXAuthorizationWrapper>
      </BrowserRouter>
    </ApolloProvider>
  );
};

ensureReady(routes).then((data) =>
  renderMethod(appRender(routes, data), document.getElementById('root'))
);

if (module.hot) {
  module.hot.accept();
}

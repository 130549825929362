import * as fs from 'fs';
import * as https from 'https';
import retailClientIDs from './../retailClientIDs.js';
import brandRegionClientIDs from './../brandRegionClientIDs.js';

export const BASE_AWS_API_URL = '#{BASE_AWS_API_URL}';
export const BASE_API_URL = '#{BASE_API_URL}';
export const ENVIRONMENT = '#{terraformEnvCode}';

export const GOOGLE_MAPS_API_TOKEN = 'AIzaSyAelAOKYcuEkJWiR2eJW7IwkWWo1eA0z6Q';

export const LAMBDA_TOKEN = '#{lambda_token}';

export const FS_DEV_EXISTS = typeof fs.readFileSync !== 'undefined';

export const getCertAgent = () => {
  return ENVIRONMENT.indexOf('prod') === -1 && FS_DEV_EXISTS
    ? new https.Agent({
        ca: fs.readFileSync(`./ClaytonRootCA.cer`),
        keepAlive: false,
      })
    : null;
};

export const getBaseAwsApiUrl = () => {
  if (BASE_AWS_API_URL.indexOf('BASE_AWS_API_URL') !== -1) {
    return 'https://api.claytonhomes.itg.cct-pubweb.com/';
  }
  return BASE_AWS_API_URL;
};

export const getBaseApiUrl = () => {
  if (BASE_API_URL.indexOf('BASE_API_URL') !== -1) {
    return 'https://dev.cmhinfo.biz/WebServices/';
  }
  return BASE_API_URL;
};

export const getGAClientId = () => {
  if (typeof gtag === 'undefined') {
    return '';
  }

  const clientIdPromise = new Promise((resolve) => {
    gtag('get', 'G-R6K1X9G3LN', 'client_id', resolve);
  });

  if (typeof gaGlobal !== 'undefined' && gaGlobal.vid) {
    let clientIDgaGlobal = gaGlobal?.vid?.toString();
    return clientIDgaGlobal;
  } else {
    clientIdPromise.then((client_id) => {
      return client_id;
    });
  }
};

export const GOOGLE_MAPS_STYLES = [
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry',
    stylers: [{ color: '#ebf1f5' }],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [{ color: '#f4f4f4' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#d2edc1' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ color: '#777b7e' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#777b7e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c3dcf3' }],
  },
];

export const PRICE_INCREMENT = 25000;

export const STICKER_TYPES = {
  '-7': { title: 'IN STOCK', className: 'sticker-in-stock' },
  '-6': { title: 'COMING SOON', className: 'purple-sticker' },
  '-5': { title: 'CROSSMOD ELIGIBLE' },
  '-4': { title: 'CROSSMOD' },
  '-3': { title: 'SALE', className: 'sticker-sale' },
  '-2': { title: 'NEW ARRIVAL' },
  '-1': { title: 'SOLD', className: 'orange-sticker' },
  1: { title: 'MOVE-IN READY' },
  2: { title: 'UPGRADES INCLUDED' },
  3: { title: 'GREAT VALUE' },
  4: { title: 'QUICK DELIVERY' },
  5: { title: 'SALE PENDING', className: 'orange-sticker' },
  6: { title: 'PRICE DROP', className: 'sticker-price-drop' },
};

export const getAuth0Domain = () => {
  let tempEnv = ENVIRONMENT;

  if (tempEnv.indexOf('dev') !== -1) {
    return 'auth.dev.myhomeaccount.com';
  } else if (tempEnv.indexOf('itg') !== -1) {
    return 'auth.itg.myhomeaccount.com';
  } else if (tempEnv.indexOf('qua') !== -1) {
    return 'auth.qua.myhomeaccount.com';
  } else if (tempEnv.indexOf('prod') !== -1) {
    return 'auth.myhomeaccount.com';
  } else {
    return 'auth.dev.myhomeaccount.com';
  }
};

export const getAuth0ClientId = () => {
  let tempEnv = ENVIRONMENT;
  let auth0ClientId;

  switch (tempEnv) {
    case 'dev':
      auth0ClientId = 'D7oDUEEirJ8E6QgU8xfn3Gvak5qQ8g5i';
      break;
    case 'itg':
      auth0ClientId = 'MP1ixj4lJ15YgPQniKldFoMGzcjMklWO';
      break;
    case 'qua':
      auth0ClientId = 'Imw9KqZNB9JLl59jneys6LyuAsNANG4n';
      break;
    case 'prod':
      auth0ClientId = 'Imw9KqZNB9JLl59jneys6LyuAsNANG4n';
      break;
    default:
      auth0ClientId = 'D7oDUEEirJ8E6QgU8xfn3Gvak5qQ8g5i';
      break;
  }

  return auth0ClientId;
};

export const getRetailClientId = (brandRegion) => {
  let cEnv = ENVIRONMENT;
  let brandRegionArray = brandRegionClientIDs;

  const foundBrandRegion = brandRegionArray?.find(
    (item) => item.brand_region == brandRegion
  );

  if (cEnv.indexOf('dev') !== -1) {
    return foundBrandRegion?.client_ids?.dev;
  } else if (cEnv.indexOf('itg') !== -1) {
    return foundBrandRegion?.client_ids?.itg;
  } else if (cEnv.indexOf('qua') !== -1) {
    return foundBrandRegion?.client_ids?.qua;
  } else if (cEnv.indexOf('prod') !== -1) {
    return foundBrandRegion?.client_ids?.prod;
  } else {
    return foundBrandRegion?.client_ids?.dev;
  }
};

export const getGraphUserURI = () => {
  let tempEnv = ENVIRONMENT;

  if (tempEnv.indexOf('dev') !== -1) {
    return 'https://api-clayton-dx-orchestration.dev.cct-pubweb.com/graphql';
  } else if (tempEnv.indexOf('itg') !== -1) {
    return 'https://api-clayton-dx-orchestration.itg.cct-pubweb.com/graphql';
  } else if (tempEnv.indexOf('qua') !== -1) {
    return 'https://api-clayton-dx-orchestration.qua.cct-pubweb.com/graphql';
  } else if (tempEnv.indexOf('prod') !== -1) {
    return 'https://api.claytonhomes.com/graphql';
  } else {
    return 'https://api-clayton-dx-orchestration.dev.cct-pubweb.com/graphql';
  }
};
